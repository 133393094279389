a :hover {
    color: #ff964a;
    text-decoration: underline;
}

.filter-link:hover {
    color: #ff964a;
    text-decoration: underline;
}

@font-face {
    font-family: squidFont;
    src: url(./squid_font.ttf);
}

*,
h3 {
    font-family: squidFont;
    color: white;
}

.filter-link {
    cursor: pointer;
}

.static-image {
    width: 180px;
}

.page-link {
    background-color: unset !important;
    color: white;
}

.page-item.active .page-link {
    border-color: #ff964a !important;
    color: #ff964a;
}

.filter-selected {
    color: #ff964a;
}

a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
    color: #ff964a !important;
}

marquee {
    position: absolute;
    height: 100% !important;
    min-height: 100% !important;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .pagination-section {
        font-size: 0.5rem;
    }

    .static-image {
        width: 75%;
        margin-left: 12%;
    }

    h3 {
        font-size: 20px;
    }

    .show-clip-text {
        left: 40% !important;
    }

    marquee {
        margin-top: -70%;
        height: 200% !important;
        min-height: 200% !important;
    }
}

@media only screen and (min-width: 1200px) {
    .static-image {
        width: 250px;
    }
}